'use client';

import { Form, Layout } from 'antd';
import styled from 'styled-components';

export const LoginPageWrapper = styled(Layout)`
  position: relative;
  align-items: center;
  justify-content: center;
  min-height: 100vh !important;
  padding: 2rem;
`;

export const LoginHeaderWrapper = styled.header`
  text-align: center;
  margin-block: 6rem;

  p {
    font-family: var(--font-inter-sans);
    color: #6d758f;
  }
`;

export const LoginFormWrapper = styled(Form)`
  width: 100%;
  max-width: 360px;
`;

export const LoginFooterWrapper = styled.footer`
  color: #6d758f;
  font-size: 0.875rem;
  text-align: center;
  margin-top: auto;

  > span:first-of-type {
    margin-right: 1.5rem;
  }
  > p {
    margin-top: 0.75rem;
  }
`;
